@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700;800&display=swap');

html, body {
    margin: 0;
    padding: 0;
    text-align: center;
}

h2 {
    font-style: italic;
}

* {
    font-family: 'Readex Pro', sans-serif;
    box-sizing: border-box;
}

.primary-title {
    font-size: 90px;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
}

.home h2 {
    color: white;
    margin-top: 0;
}

.primary-button {
    color: rgb(255,255,255);
    text-transform: uppercase;
    font-size: 15px;
    background: linear-gradient(45deg, rgb(254, 48, 114), rgb(255, 89, 64));
    padding: 12px 30px;
    border-radius: 30px;
    border: none;
}

.primary-button:hover {
    background: linear-gradient(260deg, rgb(254, 48, 114), rgb(255, 89, 64));
}

.secondary-button {
    color: rgb(121, 119, 119);
    background-color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
    padding: 12px 30px;
    border-radius: 30px;
    border: solid 2px rgb(121, 119, 119);
    margin: 6px;
}

.secondary-button:hover {
    color: rgb(48, 48, 48);
    border: solid 2px rgb(48, 48, 48);
}


/*-------- Navbar --------*/

nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

nav .nav-button {
    color: rgb(254, 48, 114);
    background-color: rgb(255, 255, 255);
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    margin: 10px;
    position: absolute;
    right: 20px;
    top: 20px;
}

nav .nav-button:disabled {
    background-color: rgb(181, 180, 180);
    color: rgb(189, 42, 90);
}

nav .logo-container {
    width: 170px;
    margin: 15px;
}

nav .logo-container .logo {
    width: 100%;
}

/*-------- Home page --------*/
.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.65) 0%,
            rgba(0, 0, 0, 0) 100%
    ), url('images/bg.png');
    background-size: cover;
}

.home {
    margin-top: 35vh;
}

.credit {
    color: white;
    position: absolute;
    width: 100%;
    bottom: 10px;
    text-align: center;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 1), 0px 0px 8px rgba(0, 0, 0, 1);
}

.credit a{
    color: white;
    
}

/*-------- Auth Modal --------*/
.auth-modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    margin-left: auto;
    margin-right: auto;
    max-width: 360px;
    height: 600px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 40px;
}

.auth-modal .close-icon {
    float: right;
}

.auth-modal form {
    display: flex;
    flex-direction: column;
}

.auth-modal input[type=password],
.auth-modal input[type=email] {
    padding: 8px;
    margin: 8px;
    font-size: 17px;
}

/*-------- OnBoarding Page --------*/
.onboarding {
    padding-top: 100px;
    border-top: solid 1px rgb(213, 213, 213);
}

.onboarding h2 {
    margin-bottom: -10px;
    font-size: 21px;
}

.onboarding form {
    display: flex;
    justify-content: center;
}

.onboarding form section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    max-width: 700px;
    text-align: start;
}

.onboarding form input {
    padding: 15px 30px;
    margin: 10px 0;
    font-size: 15px;
    border: solid 2px rgb(219, 219, 219);
    border-radius: 10px;
}

.onboarding form label {
    margin: 10px 0;
}

.onboarding form .multiple-input-container {
    display: flex;
    flex-direction: row;
}

.onboarding form input::-webkit-outer-spin-button,
.onboarding form input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.onboarding form .multiple-input-container input,
.onboarding form .multiple-checkboxes input {
    margin-right: 10px;
}

.onboarding form .multiple-input-container label,
.onboarding form .multiple-checkboxes label {
    padding: 10px;
    border: solid 2px rgb(144, 144, 144);
    border-radius: 10px;
    transition: all 0.3s;
    margin-right: 10px;
}

.onboarding form .multiple-input-container.dateOfBirth input {
    width: 100px;
}

.onboarding form .multiple-checkboxes {
    max-width: 800px;
}

.onboarding form .multiple-checkboxes label {
    display: inline-block;
    margin-bottom: 0;
}


.onboarding form input[type=radio] {
    display: none;
}

.onboarding form input[type=checkbox] {
    display: none;
}

.onboarding form input[type=checkbox].showGender {
    display: inline-block;
    vertical-align: middle;
    margin: 20px 0;
    margin-right: 10px;
}
.onboarding form input[type=checkbox].showGender:checked + label {
    border: none;
    vertical-align: middle;
}

.onboarding form label.showGenderContainer {
    display: inline-block;
}

.onboarding form input[type=number] {
    width: 10%;
}

.onboarding form input[type=radio] + label,
.onboarding form input[type=checkbox] + label {
    transition: all 0.3s;
}

.onboarding form input[type=radio]:hover + label,
.onboarding form input[type=checkbox]:hover + label {
    border: solid 2px #FD4B68;
    color: #FD4B68;
    cursor: pointer;
}

.onboarding form input[type=radio]:checked + label {
    border: solid 2px #FD4B68;
    background-color: #FD4B68;
    color: white;
}

.onboarding form input[type=checkbox]:checked + label {
    border: solid 2px #FD4B68;
    background-color: #FD4B68;
    color: white;
}




.onboarding form input[type=submit] {
    cursor: pointer;
    transition: all 0.3s;
    background-color: white;
    color: #FD4B68;
    border: 2px solid #FD4B68;
    margin-top: 40px;
    margin-bottom: 70px;
}

.onboarding form input[type=submit]:hover {
    background-color: #FD4B68;
    color: white;
}

.onboarding form input[type=submit]:active {
    background-color: rgb(226, 115, 115);
}


.onboarding form .profilePhotoPreview{
    width: 100%;
    padding-bottom: 150%;
    background-repeat: no-repeat;
    background-size: 208% 100%;
    background-position: center;
    border-radius: 10px;
    margin: 0 auto;
}

.onboarding form .photo-container img {
    width: 100%
}

.onboarding form .ctaButton {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(249, 249, 249);
    border: solid 2px rgb(200, 200, 200);
    margin-top: 20px;
    text-align: center;
    font-weight: normal;
    color: rgb(57, 57, 57);
    cursor: pointer;
    transition: all 0.5s;
}

.onboarding form .videoContainer {
    position: relative;
}

.onboarding form .selfieResultOverlay.inactive {
    display: none;
}

.onboarding form .selfieResultOverlay.active {
    display: block;
}

.onboarding form .selfieResultOverlay {
    position: absolute;
    background-color: white;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.onboarding form .videoContainer .ctaButton {
    width: 30%;
    margin-left: 20px;
}

.onboarding form .ctaButton:hover {
    background-color: #FD4B68;
    border-color: #FD4B68;
    color: white;
}

#testExpressionResult {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-style: italic;
    margin-bottom: 10px;
    transition: all 0.3s;
}

#testExpressionResult.visible {
    display: block;
}

#testExpressionResult.hidden {
    display: none;
}

#testExpressionResult.notUgly {
    color: #FD4B68;
}

#testExpressionResult.ugly {
    color: rgb(15, 231, 120);
}

#testExpressionResult.animated {
    transform: rotate(-13deg) scale(1.3);
}


#selfieButton {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background-color: rgb(198, 198, 198);
    outline: 2px solid white;
    outline-offset: -6px;
    margin: 10px auto;
    margin-top: 25px;
    cursor: pointer;
    transition: all 0.5s;
}

#selfieButton.visible {
    display: block;
}

#selfieButton.hidden {
    display: none;
}

#selfieButton.inactive:hover {
    background-color: rgb(225, 225, 225);
}

#selfieButton.active:hover {
    background-color: rgb(15, 231, 120);
}

#selfieButton:active {
    transition: all 0s;
    background-color: #FD4B68;
}

#inputVideo {
    border: 1px solid grey;
    width: 100%;
}

#inputVideo.hidden {
    display: none;
}

#inputVideo.visible {
    display: block;
}


#screencapture {
    width: 500px; 
    height: 500px; 
    border: 1px solid cyan;
    display: none;
}

.onboarding h3 {
    font-weight: normal;
    margin-bottom: 0;
    font-size: 16px;
    text-align: center;
}

#imageToSave {
    position: absolute;
    width: 45%;
    padding-bottom: 72%;
    right: 12px;
    top: 12px;
    border-radius: 10px;
    background-size: 208% 100%;
    background-position: center;
    border: 2px solid #FD4B68;
}

/*-------- Dashboard  --------*/
.dashboard {
    display: flex;
    justify-content: space-between;
}

.dashboard .swipe-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.swipe {
    position: absolute;
}

.dashboard .card-container {
    width: 95vw;
    height: 80vh;
    max-width: 414px;
    max-height: 780px; 
}

.logoContainer {
    position: absolute;
    text-align: center;
    top: 3vh;
    left: 0;
    right: 0;
}

.logoContainer img {
    width: 30%;
    max-width: 150px;
}

.card {
    width: 95vw;
    height: 80vh;
    max-width: 414px;
    max-height: 780px;
    border-radius: 30px;
    background-size: cover;
    background-position: center;
}

.card .userInfo{
    position: absolute;
    bottom: 120px;
    left: 30px;
}

.card h3 {
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    color: white;
    font-size: 40px;
    font-weight: 400;
    text-shadow: 1px 1px 22px rgba(0, 0, 0, 0.6);
}

.card h3 span {
    margin-left: 6px;
    font-size: 30px;
    font-weight: 200;
}

.card .location {
    text-align: left;
    color: white;
    text-shadow: 1px 1px 15px rgba(0, 0, 0, 0.6);
    font-weight: 300;
    font-size: 13px;
}

.card .location span,
.card .location img  {
    vertical-align: middle;
}

.card .location img  {
    margin-right: 5px;
}

.dashboard .swipe-info {
    position: absolute;
    bottom: 0;
    padding: 10px;
}

.dashboard .qualities {
    text-align: left;
    color: white;
    font-style: italic;
    text-shadow: 1px 1px 15px rgba(0, 0, 0, 0.6);
    font-size: 18px;
    margin-bottom: 5px;
    padding-right: 2px;
}

.dashboard .swipeButtons {
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.dashboard .swipeButtons .swipeButton {
    vertical-align: middle;
    margin: 0 1.6%;
}

.dashboard .swipeButtons .swipeButton.big {
    width: 17%;
}
.dashboard .swipeButtons .swipeButton.small {
    width: 14%;
}

.dashboard .swipeButtons .swipeButton {
    transition: all 0.2s;
    cursor: pointer;
}

.dashboard .swipeButtons .swipeButton:hover {
    transform: scale(1.1);
}

.dashboard .swipeButtons .swipeButton:active {
    transform: scale(0.8) rotate(-15deg);
}

.wtfElementThree {
    position: absolute;
    font-size: 40px;
    z-index: 999;
    pointer-events: none; 
    animation-name: crawlLeft;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    transform: translate(0, 0);
}

.wtfElementsFour {
    position: absolute;
    font-size: 90px;
    z-index: 9999;
    pointer-events: none;
}

@keyframes crawlLeft {
    0% {transform: translate(0, 0);}
    50% {transform: translate(-1000px, 0);}
    100% {transform: translate(0, 0);}
}

.menuButtons {
    position: fixed;
    bottom: 0;
    padding-bottom: 1.5vh;
    left: 0;
    right: 0;
    background-color: white;
}

.widthLimitWrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 450px;
}

.menuButtons .menuButton {
    vertical-align: middle;
    width: 60px;
    margin: 0 1%;
    cursor: pointer;
}

/*-------- Chat Container --------*/
.chat-container {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    width: 100%;
    text-align: center;
    z-index: 1;
}

.chat-container .chat-container-header {
    background: linear-gradient(45deg, rgb(254, 48, 114), rgb(255, 89, 64));
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-container .option {
    border: none;
    background-color: rgb(255, 255, 255);
    border-bottom: solid 4px rgb(243, 33, 33);
    font-size: 20px;
    margin: 2px;
    padding: 10px;
}

.chat-container .option:disabled {
    border-bottom: solid 4px rgb(187, 187, 187);
}

.chat-container .profile,
.chat-container .log-out-icon {
    display: flex;
    align-items: center;
    padding: 20px;
    color: rgb(255, 255, 255);
}

.img-container {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    overflow: hidden;
    margin: 10px;
}

.img-container img {
    width: 130%;
    height: 106%;
}

/*-------- Chat Display --------*/
.chat-display,
.matches-display {
    padding: 20px;
    height: 100vh;
    overflow-y: auto;
}

/*-------- Chat Input --------*/
.chat-input {
    padding: 20px;
    display: flex;
    flex-direction: column;
}















































